<template>
  <div class="login-page">
    <div class="login-box">
      <div class="title-box">
        <img
          alt="logo"
          class="antd-pro-layouts-user-layout-logo"
          src="~@/assets/logo.png"
        />
        <span class="antd-pro-layouts-user-layout-title">三只鸟云课堂-教师云平台</span>
      </div>
      <div class="antd-pro-layouts-user-layout-desc">建筑培训云课堂尽在“三只鸟云课堂”，让建筑培训变得更简单。</div>

      <div class="form-box">
        <a-form-model ref="loginForm" :model="loginForm" @submit="submit" :rules="rules" @submit.native.prevent>
          <a-form-model-item prop="account">
            <a-input v-model="loginForm.account" size="large" placeholder="用户名">
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="password">
            <a-input v-model="loginForm.password" type="password" size="large" placeholder="密码">
              <a-icon slot="prefix" type="lock" />
            </a-input>
          </a-form-model-item>

          <a-form-model-item>
            <a-button type="primary" size="large" html-type="submit" block>登录</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.form-box {
  width: 368px;
}
.login-page {
  padding-top: 120px;
  width: 100vw;
  height: 100vh;
  background: #f0f2f5;
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.antd-pro-layouts-user-layout-logo {
  height: 32px;
  margin-right: 16px;
  vertical-align: top;
}
.antd-pro-layouts-user-layout-title {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  font-family: Avenir, helvetica neue, Arial, Helvetica, sans-serif;
}
.antd-pro-layouts-user-layout-desc {
  margin-top: 12px;
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
</style>



<script>
export default {
  data() {
    return {
      rules: {
        account: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
        ],
      },
      loginForm: {
        account: "",
        password: "",
      },
    };
  },
  methods: {
    submit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
            this.$request.common.login(this.loginForm).then(({data})=>{
  
                if(0 === data.errcode){
                  this.$message.success(data.msg);

                  this.$store.set('wechat_control_token',data.data.token);
                  this.$store.set('teacher_info',data.data.user);
                  let path = '/';

                  if(!!this.$route.query.uri){
                    path  = this.$route.query.uri
                  }
                  let _this = this
                  setTimeout(function(){
                    _this.$router.push({
                      path:path
                    })
                  },2000)

                }else{
                  this.$message.error(data.msg)
                }
            })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>