import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import request from './sevices'
import 'ant-design-vue/dist/antd.css';
import store from 'store'
Vue.config.productionTip = false;

Vue.use(Antd);
Vue.prototype.$request = request;
Vue.prototype.$store = store


router.beforeEach((to, from, next) => {

  let token = store.get('wechat_control_token', '')
  if ('/login' === to.path) {
    next();
  } else if ('' === token) {
    next({
      path: '/login'
    });

  } else {
    next();
  }


})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
