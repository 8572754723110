import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Plan from '../views/pages/plan.vue'
import Download from '../views/pages/download.vue'
import Login from '../views/Login.vue'

import Guide from '../views/pages/Guide.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      // {
      //   path: 'calendar',
      //   name: '课程表',
      //   component: () => import('../views/pages/curriculum.vue')
      // },
      {
        path: '/',

        redirect: '/plan',
      },{
        path:'guide',
        name:'直播测试',
        component:Guide
      },
      {
        path: 'plan',
        name: '直播任务',
        component: Plan
      }, {
        path: 'download',
        name: '软件下载',

        component:Download
      }
    ]
  }, {
    path: '/login',
    name: '用户登录',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})




export default router
