import axios from './http'

const common = {
    login(params={}){
        return axios.post('/login.html',params)
    },
    plan(params={}) {
        return axios.post('/actions/course.html',params)
    },
    courseware(params={}){
        return axios.post('/courseware.html',params)
    }
}





export default common;