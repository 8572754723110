.<template>
  <div class="pages">
    <div class="page-headers">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <a-icon type="home" />
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <span>控制台</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> <span>直播任务</span> </a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="page-content">
      <div class="top-panel">
        <div class="tips">
          <a-alert
            message="提示"
            description="《三只鸟云课堂》直播软件开启直播后，点击“刷新”按钮，然后可点击对应直播课下方的“观看直播”按钮，扫码或者新窗口打开观看当前直播;"
            type="info"
            show-icon
          />
        </div>
        <div class="btn mgt-12">
          <a-button type="primary" icon="retweet" @click="fetch()"
            >刷新</a-button
          >
        </div>
        
      </div>

      <a-row type="flex" :gutter="12" class="mgt-12">
        <a-col
          :xs="24"
          :md="12"
          :lg="8"
          :xxl="6"
          v-for="item in data"
          :key="item.id"
        >
          <a-card :title="item.title">
            <div class="attr">
              <div class="attr-item">
                <div class="attr-item-title">计划名称：</div>
                <div class="attr-item-value">{{ item.planTitle }}</div>
              </div>
              <div class="attr-item">
                <div class="attr-item-title">直播日期：</div>
                <div class="attr-item-value">{{ item.learn_date }}</div>
              </div>
              <div class="attr-item">
                <div class="attr-item-title">直播时段：</div>
                <div class="attr-item-value">
                  {{ item.start_time | toTime }} ~ {{ item.end_time | toTime }}
                </div>
              </div>
              <div class="attr-item">
                <div class="attr-item-title">直播课件：</div>
                <div class="attr-item-value">
                  <span class="color-success" v-if="ispdf(item.pdf)"
                    >有课件</span
                  >
                  <span class="color-error" v-else>无课件</span>
                </div>
              </div>
            </div>

            <template slot="extra">
              <span class="success" v-if="1 === item.status"
                ><a-badge status="success" /> 直播中</span
              >
              <span class="processing" v-else-if="2 === item.status"
                ><a-badge status="processing" /> 回放已生成</span
              >
              <span class="default" v-else-if="3 === item.status"
                ><a-badge status="default" /> 未直播</span
              >
              <span class="error" v-else-if="4 === item.status"
                ><a-badge status="error" /> 直播中断</span
              >
              <span class="warning" v-else-if="5 === item.status"
                ><a-badge status="warning" /> 回放生成中</span
              >
              <span class="default" v-else
                ><a-badge status="default" /> 未知状态</span
              >
            </template>

            <template slot="actions" class="ant-card-actions">
              <span class="" key="setting" @click="uploadKj(item)"
                ><a-icon type="upload" />
                <span v-if="ispdf(item.pdf)">更新课件</span
                ><span v-else>上传课件</span></span
              >

              <span
                class="play-live"
                key="play"
                v-if="islive(item)"
                @click="showlive(item)"
                ><a-icon type="video-camera" /> 开始直播</span
              >

              <span key="see" v-if="1 === item.status" @click="showqr(item)"
                ><a-icon type="qrcode" /> 观看直播</span
              >
            </template>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <a-modal
      title="观看直播"
      :visible="live.visible"
      :mask-closable="false"
      @cancel="live.visible = false"
      :footer="null"
    >
      <template v-if="'' !== live.url">
        <p>方式一：<a target="_blank" :href="live.url">本地浏览器观看</a></p>
        <p>方式二，扫码观看：</p>
        <p>
          <vue-qr :text="live.url" :size="200"></vue-qr>
        </p>
      </template>
      <template v-else>
        <a-empty description="暂无直播" />
      </template>
    </a-modal>
    <a-modal
      title="上传或更新课件"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      :mask-closable="false"
      @cancel="visible = false"
      cancel-text="取消"
      ok-text="确定保存"
    >
      <a-upload-dragger
        name="file"
        accept="application/pdf"
        action="http://t.szn.sznykt.com/uploads.html"
        @change="handleChange"
        :data="{ name: courseid }"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">单击或拖动文件到此区域进行文件上传</p>
        <p class="ant-upload-hint">
          仅支持PDF文件上传。也可以在下方文件地址栏中填入PDF文件的网络地址
        </p>
      </a-upload-dragger>

      <div style="padding: 12px 0">
        <a-input addon-before="文件地址" v-model="pdflink" />
      </div>
    </a-modal>
  </div>
</template>
<style lang="less" scoped>

.color-success {
  color: #52c41a;
}
.color-error {
  color: #f5222d;
}
.color-default {
  color: #d9d9d9;
}
.color-processing {
  color: #1890ff;
}
.color-warning {
  color: #faad14;
}
.mgt-12 {
  margin-top: 12px;
}
.pages {
  display: flex;
  flex-direction: column;
  .page-headers {
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    line-height: 40px;
    margin-bottom: 16px;
    flex-shrink: 0;
    color: #999;
    position: relative;
    &:before {
      position: absolute;
      width: 3px;
      top: 12px;
      left: 0;
      height: 16px;
      background: #999;
      content: " ";
      border-radius: 3px;
    }
  }
  .page-content {
    width: 100%;
    flex: 1;
    .panel-table {
      margin-top: 12px;
      background: #fff;
    }
  }
  .search-box {
    background: #fff;
    border-radius: 4px;
    padding: 8px 16px;
    margin-bottom: 12px;
  }
}
.attr {
  .attr-item {
    display: flex;
    color: #333;
    padding: 4px 0;
    .attr-item-title {
      flex-shrink: 0;
      width: 80px;
      color: #666;
    }
    .attr-item-value {
      flex: 1;
    }
  }
}
.play-live {
  color: #1890ff;
}
</style>

<script>
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: 100,
  },
  {
    title: "课次名称",
    dataIndex: "title",
  },
  {
    title: "课件",
    dataIndex: "pdf",
    scopedSlots: { customRender: "pdf" },
  },
  {
    title: "所属计划",
    dataIndex: "plan.title",
  },
  {
    title: "计划类型",
    dataIndex: "types",
    width: 100,
  },
  {
    title: "培训时间",
    scopedSlots: { customRender: "date" },
    width: 280,
  },
  {
    title: "所属机构",
    dataIndex: "organization",
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    width: 100,
  },
];
import VueQr from "vue-qr";
import moment from "moment";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      formInline: {
        types: [1, 2],
        keywords: "",
      },
      courseid: 0,
      data: [],
      pagination: {},
      loading: false,
      columns,
      searchData: "",
      visible: false,
      confirmLoading: false,
      pdflink: "",
      oldpdf: "",
      live: {
        visible: false,
        url: "",
      },
      moduleid: 0,
    };
  },
  mounted() {
    this.searchData = JSON.stringify(this.formInline);
    this.fetch();
  },
  filters: {
    toTime(dt) {
      return moment(dt).format("HH:mm");
    },
  },
  methods: {
    ispdf(link) {
      let str = link || "";
      if ("" !== str && str.substr(str.lastIndexOf("/") + 1) !== "def.pdf") {
        return true;
      } else {
        return false;
      }
    },
    showqr(item) {
      this.live.url = `http://szn.sznykt.com/html/live.html?s=${item.planType}_${item.plan_id}_${item.id}`;
      this.live.visible = true;
    },
    handleChange(info) {
      const status = info.file.status;

      if (status === "done") {
        const response = info.file.response;

        if (response.errcode === 0) {
          this.pdflink = response.data.url;
          this.$message.success(`${info.file.name} 上传成功.`);
        } else {
          this.$message.error(`${info.file.name} 上传失败, ${response.msg}`);
        }
      } else if (status === "error") {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    handleOk() {
      if (this.pdflink === this.oldpdf) {
        this.$message.warning("课件无变动，无需修改");
      } else {
        this.confirmLoading = true;
        this.$request.common
          .courseware({
            id: this.courseid,
            pdf: this.pdflink,
            moduleid: this.moduleid,
          })
          .then(({ data }) => {
            this.confirmLoading = false;
            if (0 === data.errcode) {
              this.$message.success("课件保存成功");
              this.visible = false;
              this.fetch();
            } else {
              this.$message.error(data.msg);
            }
          });
      }
    },
    uploadKj(item) {
      this.courseid = item.id;
      this.pdflink = item.pdf;
      this.oldpdf = item.pdf;
      this.moduleid = item.planType;
      this.visible = true;
    },
    islive(item) {
      let t = moment().isBetween(
        moment(item.start_time).add(-11, "m"),
        moment(item.end_time),
        "minute"
      );

      if (t) {
        if (item.status === 3 || item.status === 4) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showlive(item) {
      console.log(item);
      let t = moment().isBetween(
        moment(item.start_time).add(-11, "m"),
        moment(item.end_time),
        "minute"
      );
      if (!t) {
        this.$warning({
          title: "不在课程的直播时段",
          content: `本课程的直播时间是: ${item.start_time} ~ ${item.end_time}；请在课程直播时间段开启直播（可提前10分钟开启直播;比如：开始时间为：09:00; 那么8：50可开启直播）`,
        });
      } else {
        var row = {
          className: item.planTitle,
          cpName: item.title,
          identityId: item.plan_id,
          cpId: item.id,
          teacherName: "teacher_" + (item.teacher || item.plan.teacher),
          endTimeForMat: item.end_time,
        };
        let str = `--classhead|${row.className}|--classsubhead|${row.cpName}|--teacherid|${item.planType}_${row.cpId}|--teachername|${row.teacherName}|endtime|${row.endTimeForMat}`;
        console.log(str);
        let url = encodeURI(str);
        window.location.href = `sznykt://${url}`;
      }
    },

    fetch(params = {}) {
      this.loading = true;
      let searchData = JSON.parse(this.searchData);
      this.$request.common
        .plan({ ...params, ...searchData })
        .then(({ data }) => {
          this.loading = false;
          if (data.errcode === 0) {
            const pagination = { ...this.pagination };
            pagination.total = data.data.total;

            this.data = data.data.items;
            this.pagination = pagination;
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
};
</script>