<template>
  <a-card>
    <a-timeline>
      <a-timeline-item>
        <div class="step-descript">
          点击下方按钮，唤醒“三只鸟云课堂”直播软件:
        </div>
        <a-button type="primary" @click="showlive()"> 开始直播 </a-button>
      </a-timeline-item>
      <a-timeline-item>
        <div class="step-descript">选择授课方式:</div>
        <img width="70%" src="@/assets/20210831114740.png" />
      </a-timeline-item>
      <a-timeline-item>
        <div class="step-descript">
          确定授课方式后，点击右上角“开始直播”按钮，直播会在倒计时后自动开始；
        </div>
        <img width="70%" src="@/assets/2.png" />
      </a-timeline-item>
      <a-timeline-item>
        <div class="step-descript">观看直播视频:</div>
        <a target="_blank" :href="url">观看直播</a>

        <div class="step-descript" style="margin-top:24px">扫码观看:</div>

        <vue-qr v-if="''!== url"  :text="url" :size="200"></vue-qr>

      </a-timeline-item>
    </a-timeline>
  </a-card>
</template>

<style lang="less" scoped>
.bg {
  background: #fff;
}
.step-descript {
  padding-bottom: 12px;
}
</style>
<script>
import VueQr from 'vue-qr'
import moment from "moment";
export default {
    components:{
        VueQr
    },
  data() {
    return {
      user: {
        id: 0,
        name: "",
      },
      show: false,
      url:'',
    };
  },
  created() {
    const info = this.$store.get("teacher_info");
    this.user.id = info.tid;
    this.user.name = info.name;
    this.url = `http://www.sznykt.com/html/live.html?s=0_${info.tid}`
  },
  methods: {
    showlive() {
      console.log(this.user);
      var row = {
        className: `${this.user.name} 直播测试`,
        cpName: "直播测试",
        identityId: 0,
        cpId: this.user.id,
        teacherName: `teacher_${this.user.name}`,
        endTimeForMat: moment().add(10, "m").format("YYYY-MM-DD HH:mm:ss"),
      };

      let str = `--classhead|${row.className}|--classsubhead|${row.cpName}|--teacherid|${row.identityId}_${row.cpId}|--teachername|${row.teacherName}|endtime|${row.endTimeForMat}`;

      let url = encodeURI(str);
      window.location.href = `sznykt://${url}`;
    },
  },
};
</script>