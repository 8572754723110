<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider width="260" v-model="collapsed" :trigger="null" collapsible>
      <div class="logo">
        <div class="img">
          <img src="~@/assets/logo.png" style="width: 24px; height: 24px" />
        </div>
        <div class="title" :class="{ none: collapsed }">三只鸟云课堂</div>
      </div>
      <a-menu
        theme="dark"
        mode="inline"
        @click="menuClick"
        :default-selected-keys="['curriculum']"
      >
        <!-- <a-menu-item key="calendar">
          <a-icon type="table" />
          <span>我的课程表</span>
        </a-menu-item> -->
        <a-menu-item key="guide">
          <a-icon type="robot" />
          <span>直播测试</span>
        </a-menu-item>
        <a-menu-item key="plan">
          <a-icon type="video-camera" />
          <span>直播任务</span>
        </a-menu-item>
        <a-menu-item key="download">
          <a-icon type="download" />
          <span>直播软件下载</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0; display: flex">
        <div style="flex: 1">
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
        </div>
        <div class="loginout" @click="logout()">
          <a-icon type="logout" /> 退出
        </div>
      </a-layout-header>
      <a-layout-content class="layout-content">
        <router-view class="router-view" />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
export default {
  data() {
    return {
      collapsed: false,
    };
  },
  methods: {
    menuClick(e) {
      let key = e.key;

      this.$router.push({ path: key });
    },
    logout() {
      this.$store.remove("wechat_control_token");
      this.$store.remove("teacher_info");
      this.$store.clearAll()
      this.$router.push('/login')
    },
  },
};
</script>
<style lang="less" scoped>
.loginout {
  flex-shrink: 0;
  padding: 0 12px;
  color: #1890ff;
  cursor: pointer;
  &:hover {
    background: #1890ff;
    color: #fff;
  }
}
.layout-content {
  margin: 16px;
  display: flex;
  flex-direction: column;
  .router-view {
    flex: 1;
  }
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  display: flex;

  .img {
    width: 48px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title {
    padding-left: 12px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 700;
    line-height: 32px;
  }
  .none {
    display: none;
  }
}
</style>
